import React from "react"

const Ballon = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
        viewBox="0 0 210.55 292.11"
      >
        <path
          fill="none"
          stroke="#4fb866"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="8"
          d="M107.54 4c50.35 0 99 39.63 99 100 0 17-14.82 75.39-78.21 125.43v19.71H80.44v-19S4 166.84 4 99.42 57.2 4 107.54 4z"
        />
        <path
          fill="none"
          stroke="#4fb866"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="8"
          d="M58.64 208.51L150.25 208.51"
        />
        <path
          fill="none"
          stroke="#4fb866"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="8"
          d="M128.85 231.02L81.7 231.02"
        />
        <path
          fill="none"
          stroke="#4fb866"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="8"
          d="M75.82 251.02v24.51s4.28 12.58 11.38 12.58h34.82s12.7-5.8 12.7-12.58v-24.49zM94.52 205.22l-16.15-94.36C72.25 52.42 87.84 23.62 97.61 4.42"
        />
        <path
          fill="none"
          stroke="#4fb866"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="8"
          d="M75.66 8.03c-13.71 9.7-37.21 36-37.21 80.36 0 49.32 38.54 120 38.54 120M116.67 205.07l16.14-94.35c6.13-58.43-9.47-87.25-19.28-106.46"
        />
        <path
          fill="none"
          stroke="#4fb866"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="8"
          d="M135.53 7.88c13.7 9.71 37.2 36 37.2 80.36 0 49.32-38.54 120-38.54 120"
        />
      </svg>
    </div>
  )
}

export default Ballon
