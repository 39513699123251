import React from "react"
import styled from "styled-components"

import {
  bodyCopy,
  headlineOne,
  headlineTwo,
  MedWrapper,
  fontSizer,
  striptCallOut,
  buttonOne,
} from "../../../Utilities"
import CommunityInfo from "../About/CommunityData/CommunityCardData"
import ScriptTitle from "../../Shared/ScriptTitle"
import { Link } from "gatsby"

const WrapperCommunity = styled.div`
  ${MedWrapper};
`

const CommunityCardStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  transition: all 0.3s ease;

  @media (min-width: 768px) {
    margin: 5rem auto;
  }

  @media (min-width: 1025px) {
    margin: 5rem auto 0;
  }

  .card-image-section {
    width: calc(100%);

    @media (min-width: 768px) {
      width: calc(30% - 2rem);
      margin-right: 2rem;
    }

    .icon {
      max-width: 10rem;
      margin: 3rem auto;
    }

    .image {
      margin: 2rem 1rem;
      border-radius: 50%;
      overflow: hidden;
    }
  }

  .card-info-section {
    width: calc(100%);
    padding-top: 1rem;

    @media (min-width: 768px) {
      width: calc(60% - 4rem);
      margin-left: 4rem;
      padding: 4rem;
    }

    h1 {
      ${headlineOne};
      ${fontSizer(2.2, 3.8, 76.8, 110, 2.6)}
      margin-top: 0;
      text-transform: uppercase;
    }

    p {
      ${striptCallOut};
      color: #707070;
      font-weight: 300;
      text-align: left;
    }

    li {
      ${bodyCopy};
      ${fontSizer(1.6, 1.8, 76.8, 110, 1.8)};
      margin-bottom: 0.5rem;
      font-weight: 300;
    }
  }

  .trailBlazer {
    width: 100%;
    padding-top: 2.5rem;
    padding-bottom: 5rem;

    p {
      ${bodyCopy};
    }

    a {
      ${buttonOne};
    }
  }

  .initiativesList {
    width: 100%;

    h3 {
      ${headlineTwo(`#505050`)};
    }
  }
`

const Content = () => {
  return (
    <WrapperCommunity>
      <CommunityCardStyled className={`community-card`}>
        <div className="card-image-section">
          {/* <div className="icon">{CommunityInfo[0].svg}</div> */}
          <div className="image">{CommunityInfo[0].img}</div>
        </div>
        <div className="card-info-section">
          <div>
            <h1>{CommunityInfo[0].title}</h1>
            <p>
              We know that you can’t build strong businesses without building
              strong communities. Here are a few ways that we have combined
              purpose and passion with our community.
            </p>
          </div>

          <div className="trailBlazer">
            <ScriptTitle title="<h2 class='title-script'>TrailBlazer Fund for Non-Profits</h2>" />
            <div>
              <p>
                Switchback Creative started the TrailBlazer Fund to help
                NON-PROFITS and CHARITIES doing amazing work in their community.
              </p>
              <p>
                It is awarded yearly to a deserving winner and is valued at
                $10,000 worth of branding or web development services.
              </p>
              <Link to="/trailblazer-fund">Learn More</Link>
            </div>
          </div>

          <div className="initiativesList">
            <h3>Community Initiatives </h3>
            <ul>
              {CommunityInfo[0].points.map((point, index) => {
                return <li key={index}>{point}</li>
              })}
            </ul>
          </div>
        </div>
      </CommunityCardStyled>
    </WrapperCommunity>
  )
}

export default Content
