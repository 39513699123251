import React from "react"
import CommunityGivingHero from "../../../Elements/Images/CommunityGivingHero"

const Hero = () => {
  return (
    <div>
      <CommunityGivingHero />
    </div>
  )
}

export default Hero
