import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { standardWrapper, fontSizer, fonts, colors } from "../../../Utilities"

import Ballon from "../../../Elements/SVG/Ballon"
import PaintPad from "../../../Elements/SVG/PaintIpad"
import HeadBulb from "../../../Elements/SVG/HeadBulb"

const CommunitySelectStyled = styled.div`
  ${standardWrapper};
  width: 100%;
  margin: 4rem auto 2rem;
  padding: 0;

  @media (min-width: 768px) {
    max-width: 75rem;
  }

  .community-into__nav--item {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: calc(33.33% - 1rem);
    margin: 2rem 0.5rem;
    padding: 2rem 0.5rem;
    transition: all 0.3s ease;
    background: #fff;
    box-shadow: 2px 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #c4c4c4;

    @media (min-width: 375px) {
      padding: 2rem 1rem;
    }

    @media (min-width: 768px) {
      width: calc(33.33% - 2rem);
      margin: 1rem;
      padding: 2rem;
    }

    @media (min-width: 1025px) {
      width: calc(33.33% - 4rem);
      margin: 2rem;
      padding: 1rem 2rem;
    }

    &:hover {
      transform: scale(1.1);
    }

    .community-icon {
      width: 100%;
      max-width: 5rem;
      margin: 0.5rem auto;

      @media (min-width: 768px) {
        max-width: 7.5rem;
        margin: 2rem auto;
      }

      @media (min-width: 1025px) {
        max-width: 7.5rem;
        margin: 2rem auto;
      }

      div {
        svg {
          width: 100%;
          max-width: 5rem;
          max-height: 5rem;

          @media (min-width: 768px) {
            max-width: 7.5rem;
            max-height: 7.5rem;
          }

          @media (min-width: 1025px) {
            max-width: 7.5rem;
            max-height: 7.5rem;
          }
        }
      }
    }

    h3 {
      position: relative;
      width: 100%;
      color: #707070;
      font-family: ${fonts.fontTertiary};
      text-transform: uppercase;
      font-weight: 300;
      font-style: normal;
      font-size: 1.2rem;
      font-stretch: normal;
      line-height: 1.29;
      letter-spacing: 1.65px;
      text-align: center;
      z-index: 100;

      @media (min-width: 375px) {
        ${fontSizer(1.6, 2.4, 76.8, 110, 1.6)};
      }

      &::after {
        position: absolute;
        right: 0.5rem;
        bottom: 1rem;
        left: 0.5rem;
        height: 1rem;
        background: ${colors.colorSecondary};
        content: "";
        opacity: 0;
        z-index: -1;
      }
    }

    &.active-nav-item {
      h3 {
        &::after {
          opacity: 1;
        }
      }
    }
  }
`

const CommunityNav = props => {
  const givingActive =
    props.location === "/about/giving-back"
      ? true
      : props.location === "/about/giving-back/"
      ? true
      : false

  const workshopActive =
    props.location === "/about/workshops-speaking"
      ? true
      : props.location === "/about/workshops-speaking/"
      ? true
      : false

  const awardsActive =
    props.location === "/about/awards-recognition"
      ? true
      : props.location === "/about/awards-recognition/"
      ? true
      : false

  return (
    <CommunitySelectStyled>
      <Link
        to="/about/giving-back"
        className={`community-into__nav--item${
          givingActive === true ? " active-nav-item" : ""
        }`}
      >
        <div className="community-icon">
          <Ballon />
        </div>
        <h3>Giving Back</h3>
      </Link>
      <Link
        to="/about/workshops-speaking"
        className={`community-into__nav--item${
          workshopActive === true ? " active-nav-item" : ""
        }`}
      >
        <div className="community-icon">
          <PaintPad />
        </div>
        <h3>Workshops + Speaking</h3>
      </Link>
      <Link
        to="/about/awards-recognition"
        className={`community-into__nav--item${
          awardsActive === true ? " active-nav-item" : ""
        }`}
      >
        <div className="community-icon">
          <HeadBulb />
        </div>
        <h3>Awards + Recognition</h3>
      </Link>
    </CommunitySelectStyled>
  )
}

export default CommunityNav
