import React from "react"
import styled from "styled-components"

import { headlineTwo } from "../../Utilities/"

const StyledScriptTitle = styled.div`
  width: 100%;
  max-width: 35rem;
  margin: 0 auto;

  @media (min-width: 768px) {
    max-width: 100%;
  }

  .title-script {
    ${headlineTwo(`#505050`)};
  }
`

const ScriptTitle = ({ title }) => {
  return <StyledScriptTitle dangerouslySetInnerHTML={{ __html: title }} />
}

export default ScriptTitle
