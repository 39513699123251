import React from "react"
import Ballon from "../../../../Elements/SVG/Ballon"
import PaintPad from "../../../../Elements/SVG/PaintIpad"
import HeadBulb from "../../../../Elements/SVG/HeadBulb"

import CommunityAwards from "../../../../Elements/Images/CommunityAwards"
import CommunityGiving from "../../../../Elements/Images/CommunityGiving"
import CommunitySpeaking from "../../../../Elements/Images/CommunitySpeaking"

export default [
  {
    activeClass: "givingBack",
    title: "Giving Back",
    points: [
      "Mayor’s Night of the Arts, Media Sponsor",
      "100 Kids who Care, Contributor",
      "Artember, Past Marketing Partner and Volunteer, 2014",
      "TEDxCanmore, Board Member and Sponsor, 2016",
      "Building Futures, Marketing and Design Workshop, 2017",
      "Light Up the Night, Marketing Sponsor, 2015 ~ 2017",
      "Airdrie Business Awards, Marketing Chair, 2015 ~ 2018",
      "Airdrie Business Awards, Gold Sponsor, 2015 - 2018",
      "TrailBlazer Fund: given $60,000 worth of services, 2015 - present",
      "Airdrie Angel, Full Partner, 2015 ~ present",
      "Angel Lift Day, Organizer and Volunteer, 2015 ~ present",
      "SMARTstart, Sponsor, Session Speaker/Presenter, 2016 ~ present",
      "Daddy Hair Day, Organizer and Volunteer, 2016 ~ present",
      "Real Purpose Podcast, 2019 ~ present",
      "Shop Local Group Founding Member, 2019 - present",
      "Friends of STARS – Experience Alberta Raffle, Platinum Sponsor 2020",
    ],
    svg: <Ballon />,
    img: <CommunityGiving />,
  },
  {
    activeClass: "workshops",
    title: "Workshops + Speaking",
    points: [
      "Speaker, Gypsiella, Women’s Entrepreneurial Organization",
      "Speaker, Airdrie Chamber of Commerce, Airdrie",
      "Speaker, Cochrane Chamber of Commerce",
      "Speaker, Canmore Business & Tourism Workshop",
      "Speaker, e=mc2 The Centre for Business Excellence, Canmore",
      "Speaker, The Measurable Difference, Calgary",
      "Speaker, Corporate Culture, The Hair Lounge",
      "Speaker, ATB Entrepreneurs Centre",
      "Speaker, Bow Valley College",
      "Workshop Leader, Rural Women in Business Conference",
      "Workshop Leader, Community Futures Centre West",
      "Workshop Leader, Branding, SMARTstart Entreprenurial Program",
      "Workshop Leader, Marketing, SMARTstart Program",
      "Workshop Leader, The Pink Wand Cleaning Services",
      "Workshop Leader, Gypsiella, Women’s Entrepreneurial Organization",
    ],
    svg: <PaintPad />,
    img: <CommunitySpeaking />,
  },
  {
    activeClass: "awards",
    title: "Awards + Recognition",
    points: [
      "Anvil Award for Non-Profit Ad Campaign",
      "Winner of 4 Top Annual Report Design Awards",
      "Nominee for Amazing Airdrie Women Awards 2015",
      "Finalist for Branded Magazine, Mover and Shaker Awards 2016",
      "Finalist for Emerging Business, Airdrie Business Award, 2016",
      "Finalist for the Canadian Business Chicks, Game Changer 2017",
      "Winner Business of the Year Award, Airdrie Business Award, 2017",
      "Business Innovation, Finalist, Airdrie Business Awards, 2019",
    ],
    svg: <HeadBulb />,
    img: <CommunityAwards />,
  },
]
