import React from "react"

const PaintIpad = () => {
  return (
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 263.28 260.2">
        <g
          fill="none"
          stroke="#4fb866"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="8"
        >
          <rect width="215.94" height="200.16" x="4" y="4" rx="15.12" />
          <path d="M4 37.4L219.94 37.4" />
          <circle cx="27.4" cy="21.15" r="4.06" />
          <circle cx="45.43" cy="21.15" r="4.06" />
          <circle cx="63.35" cy="21.15" r="4.06" />
          <path d="M29.31 90.19H76.08V103.78999999999999H29.31z" />
          <path d="M28.44 119.71L76.08 119.71" />
          <path d="M27.22 135.46L85.88 135.46" />
          <path d="M26.3 151.11L95.45 151.11" />
        </g>
        <g
          stroke="#4fb866"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="8"
        >
          <path
            fill="none"
            d="M137.55 96.69c7 9.07-.12 19.44-4.39 23.37s-9.05 6.08-18.71 4.11c-22.83-4.67-14.4-58-13-55.26 2.1 7.52 11.2 15.94 20.53 18.81 12.47 3.82 15.57 8.97 15.57 8.97z"
          />
          <path
            fill="none"
            d="M142.05 113.16L149.6 121.34 131.9 137.66 124.22 129.34 142.05 113.16z"
          />
          <path
            fill="#fff"
            d="M147.3 123.46c12.63 4.06 24 12.4 40.05 29.76s70.75 90.32 70.75 90.32 3.6 4-1.76 8.93c-8.81 8.13-10.72.44-10.72.44s-62.93-57.24-82.6-75.37c-16.09-14.85-29.07-42.37-29.07-42.37z"
          />
        </g>
      </svg>
    </div>
  )
}

export default PaintIpad
