import React from "react"

const HeadBulb = () => {
  return (
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 245.47 296.32">
        <path
          fill="none"
          stroke="#4fb866"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="8"
          d="M197.02 228.07c5.82-15.24 21.15-31.47 26.77-41.22s15.14-48.33 15.14-48.33C248.38 98.8 235.35 3.96 138.69 3.96 19.54 3.96 26.84 117.54 26.84 117.54l-22.11 60c-4 16.92 9.92 16.22 9.92 16.22h21.37s3.74 27.9 6.74 47 21 15 21 15h28.55v36.35s98.11.37 98.81 0z"
          data-name="headoutline"
        />
        <g
          fill="none"
          stroke="#4fb866"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="8"
        >
          <path d="M150.85 167.37L117.13 167.37" />
          <path d="M143.55 180.42L124.06 180.42" />
          <path d="M180.64 124.09L188.89 127.84" />
          <path d="M181.95 92.81L192.07 90.37" />
          <path d="M166.97 65.69L172.75 60.21" />
          <path d="M134.71 54.96L134.71 45.03" />
          <path d="M102.14 65.69L95.61 60.21" />
          <path d="M85.84 91.59L77.22 90.37" />
          <path d="M87.53 124.09L79.47 127.84" />
          <path d="M117.13 153.5h33.73s-.19-9.55 6.37-20.79 10.68-17.55 10.68-31.29-12.18-32.41-34.89-32.41-33.87 22.11-33.87 35.22 9.7 24.17 12.7 30.16 5.28 19.11 5.28 19.11z" />
        </g>
      </svg>
    </div>
  )
}

export default HeadBulb
