import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/Seo/seo"
import Hero from "../../components/PageParts/GivingBack/Hero"
import Content from "../../components/PageParts/GivingBack/Content"
import CommunityNav from "../../components/PageParts/GivingBack/CommunityNav"

const GivingBack = props => {
  return (
    <Layout location={props.location.pathname}>
      <SEO
        title="Giving Back - Switchback Creative"
        description="Giving back is really important here at Swicthback Creative. We believe that you should combine purpose & passion with your community."
        location={props.location.pathname}
        pageImg={props.data.metaImage.publicURL}
      />
      <Hero />
      <Content />
      <CommunityNav location={props.location.pathname} />
    </Layout>
  )
}

export const givingQuery = graphql`
  {
    metaImage: file(
      relativePath: { eq: "switchback-communitypages-meta.jpg" }
    ) {
      publicURL
    }
  }
`

export default GivingBack
